<template>
  <div class="page-accounting">
    <expenses-navigation />
    <expenses
      activityTagName="ppc management"
      :projectTypes="['PPC', 'INT-PPC', 'PPC-INT']"
    />
  </div>
</template>

<script>
// Components
import Expenses from '@/agGridV2/components/expenses.component.vue'
import ExpensesNavigation from '@/components/ExpensesNavigation.vue'

export default {
  components: {
    ExpensesNavigation,
    Expenses
  },
  data() {
    return {}
  }
}
</script>
